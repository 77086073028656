import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ApiProviderService } from '@/shared/lib/services/api-provider.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  sdk = inject(ApiProviderService).sdk;

  deleteNotification = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationDelete({ notificationDelete: { id: id } }));
  };

  restoreNotification = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationRestore({ notificationRestore: { id: id } }));
  };

  markNotificationAsRead = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationMarkAsRead({ notificationMarkAsRead: { id: id } }));
  };

  markNotificationAsUnread = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationMarkAsUnread({ notificationMarkAsUnread: { id: id } }));
  };

  markNotificationAsFavorite = (id: string) => {
    return firstValueFrom(
      this.sdk.notificationsApi.notificationMarkAsFavorite({ notificationMarkAsFavorite: { id: id } })
    );
  };

  markNotificationAsUnfavorite = (id: string) => {
    return firstValueFrom(
      this.sdk.notificationsApi.notificationMarkAsUnfavorite({ notificationMarkAsUnfavorite: { id: id } })
    );
  };

  archiveNotification = (id: string) => {
    return firstValueFrom(this.sdk.notificationsApi.notificationArchive({ notificationArchive: { id: id } }));
  };

  getNotifications = (pageNumber: number, pageSize: number, filterParams?: Map<string, string | string[] | Date>) => {
    return firstValueFrom(
      this.sdk.notificationsApi.listUserNotification({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortDirection: 'descending',
        sortField: 'CreatedAt',
        ...this.convertFilterParams(filterParams),
      })
    );
  };

  getDeletedNotifications = (
    pageNumber: number,
    pageSize: number,
    filterParams?: Map<string, string | string[] | Date>
  ) => {
    return firstValueFrom(
      this.sdk.notificationsApi.listUserNotificationDeleted({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortDirection: 'descending',
        sortField: 'CreatedAt',
        ...this.convertFilterParams(filterParams),
      })
    );
  };

  convertFilterParams = (filterParams?: Map<string, string | string[] | Date>) => {
    if (!filterParams) {
      return {};
    }

    return Object.fromEntries(filterParams.entries());
  };
}
